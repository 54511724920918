'use client';

import React from 'react';
import Script from 'next/script';

interface ClientScriptProps {
  src?: string;
  id?: string;
  strategy?: 'beforeInteractive' | 'afterInteractive' | 'lazyOnload' | 'worker';
  crossOrigin?: string;
  children?: React.ReactNode;
}

/**
 * Client-side wrapper for Script component that can handle errors
 * This wrapper makes it safe to use with static site generation
 */
export default function ClientScript({
  src,
  id,
  strategy = 'afterInteractive',
  crossOrigin,
  children
}: ClientScriptProps) {
  const [hasError, setHasError] = React.useState(false);

  // Client-side error handler
  const handleError = React.useCallback((err: Error) => {
    console.log(`Script error loading ${src || id}:`, err);
    setHasError(true);
  }, [src, id]);

  // Only render script if there's no error
  if (hasError) {
    return null;
  }

  // Return the Script component with event handlers properly attached client-side
  return (
    <Script
      src={src}
      id={id}
      strategy={strategy}
      crossOrigin={crossOrigin}
      onError={handleError}
    >
      {children}
    </Script>
  );
}