'use client';

import React from 'react';
import ClientScript from './ClientScript';

/**
 * Loads all site scripts in a client-side component
 * This allows for proper error handling without breaking SSG
 */
export default function ScriptLoader() {
  return (
    <>
      {/* Google Analytics scripts */}
      <ClientScript
        src="https://www.googletagmanager.com/gtag/js?id=G-QW21NJBW7K"
        strategy="afterInteractive"
      />
      <ClientScript id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-QW21NJBW7K');
        `}
      </ClientScript>
      
      {/* AdSense script */}
      <ClientScript
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3131616609445146"
        strategy="lazyOnload"
        crossOrigin="anonymous"
      />
    </>
  );
}